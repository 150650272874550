import { RedDotListData, TmapApp, UserAllSettings } from '@tmap-web-lib/tmap-app-interface'
import dayjs from 'dayjs'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { lazy, useCallback, useEffect, useRef, useState } from 'react'

import { usePageIdContext } from '@/Providers'
import { TmapLogContextProvider, useTmapLogContext } from '@/Providers/tmap-log-context'
import { accessKeyAtom, globalState, remoteConfigServiceListAtom, storageSchemeUrl } from '@/atoms'
import { MainSkeleton } from '@/components'
import { sendEventLog } from '@/features/log/log.fn'
import {
  PlaceReview,
  ServiceList,
  UserProfile,
  UserProfileProps,
  VehicleInfo,
  VehicleInfoProps,
} from '@/features/web/components'
import {
  useGetAdsServing,
  useGetMyWholeReview,
  useGetParkingList,
  useGetTmapPayPointsSummary,
  useGetUserInfo,
} from '@/react-query'
import { CheckPageId } from '@/routes'
import { fuelTypes, vehicleModelTypes } from '@/types'
import { tmapDateStringToDateTime } from '@/utils'

import * as styles from '../../styles/pages/web.css'

const AdsBanner = lazy(() => import('@/features/web/components/ads-banner/AdsBanner'))

export default function Web() {
  const setGlobalStateValue = useSetAtom(globalState)
  const accessKey = useAtomValue(accessKeyAtom)
  const remoteConfigServiceList = useAtomValue(remoteConfigServiceListAtom)
  const [schemeUrlStorageValue, setSchemeUrlStorageValue] = useAtom(storageSchemeUrl)
  const { tmapLogClient } = useTmapLogContext()
  const isErrorLoggedRef = useRef(false)
  const pageIdContext = usePageIdContext()
  const { data: bannerList, isFetched: isBannerFetched } = useGetAdsServing({
    inventoryCode: 'IN104',
    useErrorBoundary: false,
  })
  const {
    data: pointData,
    isFetched: isPointDataFetched,
    refetch: refetchPointData,
    error: pointDataError,
  } = useGetTmapPayPointsSummary({ useErrorBoundary: false })
  const {
    data: userInfoData,
    isFetched: isUserInfoDataFetched,
    refetch: refetchUserInfoData,
    error: userInfoError,
  } = useGetUserInfo()
  const {
    data: parkingData,
    isFetched: isParkingDataFetched,
    refetch: refetchParkingData,
    error: parkingDataError,
  } = useGetParkingList({ useErrorBoundary: false })
  const {
    data: reviewData,
    isFetched: isReviewDataFetched,
    refetch: refetchReviewData,
    error: reviewDataError,
  } = useGetMyWholeReview({ useErrorBoundary: false })
  const isVerified = userInfoData?.idVrfdYn === 'Y'
  const bannerData = bannerList?.ads
  const [isMainLoading, setIsMainLoading] = useState(true)
  const [pointRedDot, setPointRedDot] = useState<RedDotListData[]>([])
  const [serviceListRedDot, setServiceListRedDot] = useState<
    Pick<RedDotListData, 'itemGroup' | 'item'>[]
  >([{ itemGroup: '', item: '' }])
  const [userProfileData, setUserProfileData] = useState<UserProfileProps>({
    loginAuthType: '',
    image: '',
    name: '',
    mdn: '',
    tid: '',
    point: 0,
    isVerified: false,
  })
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfoProps>({
    carNumber: '',
    vehicleType: '',
    fuelType: '',
    isParking: null,
  })

  const getLoginData = useCallback(async () => {
    const loginAuthType = await TmapApp.getLoginMethod()
    const userInfo = {
      loginAuthType: loginAuthType || 'None',
      tid:
        loginAuthType === 'TID' && userInfoData
          ? userInfoData.externalUserInfos[0].readableUserId
          : '',
    }

    setUserProfileData((prev) => ({ ...prev, ...userInfo }))
  }, [userInfoData])

  useEffect(() => {
    setUserProfileData((prev) => ({ ...prev, point: pointData?.availablePoint || 0 }))
  }, [pointData?.availablePoint])

  useEffect(() => {
    setUserProfileData((prev) => ({ ...prev, mdn: userInfoData?.mdn || '' }))
  }, [userInfoData])

  const getUserSetting = useCallback(async () => {
    const userSettings = (await TmapApp.getUserAllSettings()) as UserAllSettings
    const vehicleType =
      vehicleModelTypes.find((model) => model.model === userSettings?.settings.car.model)?.name ||
      '승용차'

    setUserProfileData((prev) => {
      return {
        ...prev,
        image: userSettings?.settings.user.image || '',
        name: userSettings?.settings.user.name || '',
      }
    })

    const vehicleInfo = {
      carNumber:
        vehicleType === '이륜차' && !userSettings?.settings.car.number
          ? '모터바이크'
          : userSettings?.settings.car.number || '',
      vehicleType,
      fuelType:
        vehicleType === '이륜차' ? '' : fuelTypes[userSettings?.settings.car.fuel] || '휘발유',
      isParking:
        (vehicleType !== '이륜차' &&
          parkingData?.some(
            (vehicle) => vehicle.vehicleNumber === userSettings?.settings.car.number
          )) ||
        false,
    }
    setVehicleInfo(vehicleInfo)
  }, [parkingData])

  useEffect(() => {
    getLoginData()
    getUserSetting()
  }, [getUserSetting, getLoginData])

  const getRedDotList = useCallback(() => {
    TmapApp.getRedDotList().then((recvRedDotList) => {
      const redDotList = recvRedDotList.filter(
        (data) =>
          (data.itemGroup === 'SERVICE_INDEX' ||
            data.itemGroup === 'COMMUNICATION' ||
            data.itemGroup === 'HOME' ||
            data.item.includes('BOARD-')) && // itemGroup=BOARD일 경우 게시판에 등록되는 모든 레드닷 영향 받을 수 있어 특정 게시판 전용 메뉴가 들어올 경우 boardid가 무조건 BOARD-로 시작하므로 BOARD- 포함 여부 확인
          data.displayType === 'REDDOT' &&
          tmapDateStringToDateTime(data.expiredDateTime) > dayjs() &&
          tmapDateStringToDateTime(data.startDateTime) <= dayjs() &&
          !data.readDateTime
      )
      const serviceListRedDot = redDotList.map((data) => ({
        itemGroup: data.itemGroup,
        item: data.item,
      }))
      const pointRedDot = redDotList.filter((data) => data.item === 'POINT')

      setServiceListRedDot(serviceListRedDot)
      setPointRedDot(pointRedDot)
    })
  }, [])

  // 인증 상태 변경 시 호출
  useEffect(() => {
    setUserProfileData((prev) => ({ ...prev, isVerified: isVerified }))

    if (isVerified !== null) {
      getRedDotList()
    }
  }, [isVerified, getRedDotList])

  useEffect(() => {
    if (
      isUserInfoDataFetched &&
      isBannerFetched &&
      isParkingDataFetched &&
      isPointDataFetched &&
      isReviewDataFetched &&
      remoteConfigServiceList
    ) {
      setIsMainLoading(false)
    }
  }, [
    isUserInfoDataFetched,
    isBannerFetched,
    isParkingDataFetched,
    isPointDataFetched,
    isReviewDataFetched,
    remoteConfigServiceList,
  ])

  useEffect(() => {
    if (window.location.pathname === '/web') {
      setGlobalStateValue((prev) => ({ ...prev, isSkeleton: true }))
    }
  }, [setGlobalStateValue])

  // android resume시 repaint
  useEffect(() => {
    if (TmapApp.env.isInApp) {
      const off = TmapApp.utils.addNativeEventListener('onResume', () => {
        const bodyEl = document.querySelector('body') as HTMLBodyElement
        bodyEl.style.transform = 'translate3d(0, 0, 0)'
        setTimeout(() => (bodyEl.style.transform = ''), 50)
      })
      return () => off()
    }
  }, [])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onResume', () => {
      TmapApp.getAccessKey().then(async (newAk) => {
        if (accessKey !== newAk) {
          window.location.reload()
          setGlobalStateValue((prev) => ({ ...prev, isSkeleton: false }))
          setTimeout(() => setGlobalStateValue((prev) => ({ ...prev, isSkeleton: true })), 1500)
        } else {
          getUserSetting()
          getLoginData()
          getRedDotList()
          await refetchPointData()
          await refetchUserInfoData()
          await refetchParkingData()
          await refetchReviewData()
          setGlobalStateValue((prev) => ({ ...prev, isSkeleton: false }))
          setTimeout(() => setGlobalStateValue((prev) => ({ ...prev, isSkeleton: true })), 1500)
        }
      })
    })
  }, [
    accessKey,
    setGlobalStateValue,
    getUserSetting,
    getLoginData,
    getRedDotList,
    refetchPointData,
    refetchUserInfoData,
    refetchParkingData,
    refetchReviewData,
  ])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onRefresh', () => {
      window.location.reload()
    })
  }, [])

  useEffect(() => {
    if (!isMainLoading) {
      tmapLogClient('expose').setPageId('/my').send()
    }
  }, [isMainLoading, tmapLogClient])

  useEffect(() => {
    const { extraObject = {}, pageIdInfo } = pageIdContext || {}
    const encodedLogBoxObject = encodeURIComponent(
      JSON.stringify({
        activeArea: extraObject.activeArea,
        logbox: extraObject.logbox,
      })
    )
    if (TmapApp.env.appVersion !== '0.0.0') {
      // 마이 메인이 항상 떠있는 웹뷰라 스킴으로 진입했을 때 히스토리가 쌓이거나 밑에 메인 페이지가 한 번 떠 깔리는 이슈 있음
      // AOS의 경우 replace 해도 히스토리가 쌓이는 이슈 있어 로그 정보를 가지고있는 url을 스토어에 저장 후 history.back으로 돌아가주고 (0번째 페이지로 가기 위함)
      // 저장된 url이 있으면 replace 해주도록해서 히스토리가 쌓이지 않도록 적용
      if (TmapApp.env.isAndroid) {
        if (pageIdInfo) {
          let path = `/web?extra=${encodedLogBoxObject}`
          setGlobalStateValue((prev) => ({ ...prev, schemeURL: path }))
          setSchemeUrlStorageValue(path)
          window.history.back()
        }
        if (schemeUrlStorageValue) {
          window.location.replace(schemeUrlStorageValue)
          setTimeout(() => setGlobalStateValue((prev) => ({ ...prev, schemeURL: '' })), 0)
        }
      } else {
        if (pageIdInfo) {
          window.location.replace(`/web?extra=${encodedLogBoxObject}`)
        }
      }
    }
  }, [pageIdContext, setGlobalStateValue, schemeUrlStorageValue, setSchemeUrlStorageValue])

  useEffect(() => {
    if (!isErrorLoggedRef.current) {
      if (pointDataError) {
        sendEventLog('my:error:point', { error: pointDataError })
        isErrorLoggedRef.current = true
      }
      if (parkingDataError) {
        sendEventLog('my:error:parking', { error: parkingDataError })
        isErrorLoggedRef.current = true
      }
      if (reviewDataError) {
        sendEventLog('my:error:review', { error: reviewDataError })
        isErrorLoggedRef.current = true
      }
      if (userInfoError) {
        sendEventLog('my:error:userInfo', { error: userInfoError })
        isErrorLoggedRef.current = true
      }
    }

    if (!pointDataError || !parkingDataError || !reviewDataError || !userInfoError) {
      isErrorLoggedRef.current = false
    }
  }, [pointDataError, parkingDataError, reviewDataError, userInfoError])

  return (
    <CheckPageId>
      {isMainLoading ? (
        <MainSkeleton />
      ) : (
        <TmapLogContextProvider pageId={'/my'}>
          <div className={styles.interfaceArea} />
          <UserProfile
            loginAuthType={userProfileData?.loginAuthType || ''}
            image={userProfileData?.image || ''}
            name={userProfileData?.name || ''}
            mdn={userProfileData?.mdn || ''}
            tid={userProfileData?.tid || ''}
            point={userProfileData?.point || 0}
            isVerified={userProfileData?.isVerified || false}
            redDotList={pointRedDot}
          />
          <VehicleInfo
            carNumber={vehicleInfo?.carNumber || ''}
            vehicleType={vehicleInfo?.vehicleType || ''}
            fuelType={vehicleInfo?.fuelType || ''}
            isParking={vehicleInfo?.isParking || false}
          />
          <PlaceReview data={reviewData || null} />
          {bannerData && (
            <AdsBanner
              data={bannerData}
              isLoading={!isBannerFetched}
            />
          )}
          <ServiceList redDotList={serviceListRedDot} />
        </TmapLogContextProvider>
      )}
    </CheckPageId>
  )
}
